<template>
  <div>
    <b-row class="content-header">
      <b-col class="content-header-left mb-2" cols="12" md="10">
        <b-row class="breadcrumbs-top">
          <b-col cols="10">
            <h2 class="content-header-title float-left pr-1 mb-0">Clients</h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-for="item in breadcrumbs"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
                >
                  {{ item.text }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="2" class="text-right">
        <b-button block variant="primary" to="/client/add">
          <span class="text-nowrap"
            >Add Client <feather-icon icon="PlusIcon"></feather-icon>
          </span>
        </b-button>
      </b-col>
    </b-row>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              :deselectFromDropdown="true"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" class="ml-auto" md="6">
            <div class="d-flex align-items-center justify-content-between">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search Client..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :busy="isBusy"
        ref="refUserListTable"
        class="position-relative"
        :items="clients"
        responsive
        :fields="tableColumns"
        :filter="searchQuery"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading clients...</strong>
          </div>
        </template>

        <template #empty>
          <div class="text-center">
            <b-col cols="4" class="mx-auto my-2">
              <b-button block variant="primary" to="/client/add">
                <span class="text-nowrap"
                  >Add Client <feather-icon icon="PlusIcon"></feather-icon>
                </span>
              </b-button>
            </b-col>
          </div>
        </template>

        <!-- Column: User -->
        <template #cell(client)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.logo"
                :text="avatarText(data.item.name)"
                :variant="data.item.logo ? `white` : `light-primary`"
                :to="`/client/${data.item.slug}`"
              />
            </template>
            <b-link
              :to="`/client/${data.item.slug}`"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">@{{ data.item.slug }}</small>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(access)="data">
          <b-badge
            pill
            :variant="`light-${
              data.item.access.length > 1 ? 'warning' : 'success'
            }`"
            class="text-capitalize"
          >
            {{
              data.item.access.length > 1
                ? `You & ${data.item.access.length - 1}`
                : "Only you have access"
            }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveClientStatusVariant(data.item.isActive)}`"
            class="text-capitalize"
          >
            {{ data.item.isActive ? "Active" : "Inactive" }}
          </b-badge>
        </template>

        <template #cell(action)="data">
          <b-avatar-group size="32px">
            <b-avatar
              v-if="
                data.item.integrations.googleAds &&
                data.item.googleAdsCustomerID
              "
              class="pull-up"
              variant="primary"
              v-b-tooltip.hover
              title="Google Ads Dashboard"
              :to="`/client/${data.item.slug}/dashboard/google-ads`"
              :src="require('@/assets/images/portrait/small/ads-avatar.png')"
            />
            <b-avatar
              v-if="data.item.integrations.google && data.item.googleViewID"
              class="pull-up"
              variant="primary"
              v-b-tooltip.hover
              title="Google Analytics Dashboard"
              :to="`/client/${data.item.slug}/dashboard/google-analytics`"
              :src="
                require('@/assets/images/portrait/small/analytics-avatar.png')
              "
            />
          </b-avatar-group>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  BAvatarGroup,
  VBTooltip,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "axios";
import { getUserToken } from "@/auth/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BSpinner,
    BAvatarGroup,
    BBreadcrumb,
    BBreadcrumbItem,
  },
  data() {
    return {
      perPageOptions: [5, 10, 25, 50, 100],
      perPage: 5,
      searchQuery: "",
      clients: [],
      tableColumns: [
        { key: "client", sortable: true },
        { key: "access", sortable: false },
        { key: "status", sortable: false },
        { key: "action", sortable: false, label: "" },
      ],
      sortBy: "id",
      isSortDirDesc: true,
      totalUsers: 0,
      currentPage: 1,
      isBusy: true,
      breadcrumbs: [
        {
          text: "Dashboard",
          to: "/dashboard",
        },
        {
          text: "Clients",
          to: "/client/list",
          active: true,
        },
      ],
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  watch: {
    perPage(newValue, oldValue) {
      this.fetchClients({
        limit: newValue,
        currentPage: this.currentPage,
      });
    },
    currentPage(newValue, oldValue) {
      this.fetchClients({
        limit: this.perPage,
        currentPage: newValue,
      });
    },
  },
  methods: {
    fetchClients(search) {
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/api/client/get`, search, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.clients = response.data.clients;
          this.totalUsers = response.data.total;
          this.isBusy = false;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
    resolveClientStatusVariant(status) {
      return status ? "success" : "secondary";
    },
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.clients.length || 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalUsers,
      };
    },
  },
  mounted() {
    this.fetchClients({
      limit: this.perPage,
      currentPage: this.currentPage,
    });
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
